//# babelOptions minify=yes comments=no mangle=no debuginfo=yes
// {

Y.namespace("cms.shop");
var num=new Intl.NumberFormat('de',{minimumFractionDigits:2});
var format_defines={
    format_number:n => num.format(n),
    format_datum:(d,f='%d.%m.%Y') => new Date(d).format(f),
    format_datum_zeit:(d,f='%d.%m.%Y %H:%M:%S') => new Date(d).format(f),
    format_zeit:(d,f='%H:%M:%S') => new Date(d).format(f),
    format_eingabe:(n,p,data) => `{_:${data.Typ}}`,
    format_button:(n,p,data) => `<span style="position: relative;"><input type="hidden" name="__anzahl" value="${wk.get(String(data.idx))?._anzahl}" data-idx="{idx}"><button class="wk-addbut" type="button" data-idx="{idx}">${p||'in den Warenkorb'}</button></span>`,
    format_bild:b => `<img src="${/^https?:|^\//.test(b) ? '' : '../../MEDIA/'}${b}">`,
    print_only:n => '',
    Anzahl:(n,p,data)=>`<span class="eingabe" style="position: relative;display: flex;"><span class="plusminus minus">-</span><span class="input"><input type="number" name="__anzahl" value="${wk.get(String(data.idx))?._anzahl||0}" data-idx="{idx}" min=0><span class="wk-add-msg" style="position:absolute;top:100%; left:0; width:100%"></span></span><span class="plusminus plus">+</span></span>`,
    Menge:(n,p,data)=> `<span class="eingabe" style="position: relative;display: flex;"><span class="plusminus minus">-</span><span class="input"><input type="number" name="__anzahl" value="${wk.get(String(data.idx))?._anzahl||0}" data-idx="{idx}" step="any" min=0><span class="wk-add-msg" style="position:absolute;top:100%; left:0; width:100%"></span></span><span class="plusminus plus">+</span></span>`,
    Fixpreis:(n,p,data)=>`<span style="position: relative;"><input type="checkbox" name="__anzahl" value="1" data-idx="{idx}"${wk.get(String(data.idx))?._anzahl>0 ? ' checked' : ''}><span class="wk-add-msg" style="position:absolute;top:100%; left:0; width:100%"></span></span>`,
    format_co_opt:(n,p,data)=>`<input type="radio" name="${cmap.get(n)?.Kategorie}" value="${n}"${chkoutopt.has(n) ? ' checked' : ''}>`,
    list_co_opt:(n,p,data)=>`{1:co_start}${[...chkoutopt.values].map(o => {cmslib.merge('#',o)}).join("")}{1:co_end}`
}
var amap=Y.cms.shop.amap=new Map();
var cmap=Y.cms.shop.cmap=new Map();
var chkoutopt=Y.cms.shop.chkoutopt=new Map();
var wkdivs=new Map();
function loadwk()
{
    if (window.sessionStorage)
    {
	    var wk0=new Map(JSON.parse(sessionStorage.getItem("Warenkorb") || '[]'));
	    wk0.forEach((w,i) => {
	        if (w._anzahl === 0)
	            wk0.delete(i);
	    })
	    return wk0;
    }
	else
	{
	    Y.error('sessionStorage nicht verfügbar');
	    return new Map();
	}
}
function savewk(wk)
{
    if (window.sessionStorage)
        sessionStorage.setItem("Warenkorb",JSON.stringify([...wk]));
    else
    {
        Y.error('sessionStorage nicht verfügbar');
    }
}

var wk=Y.cms.shop.wk=loadwk();
Y.cms.shop.wrartikel=function(aobj)
{
    var adiv=document.currentScript.parentElement;
    adiv.dataset.addmsg=aobj.config.Text_hinzugefuegt || "";
    adiv.dataset.delmsg=aobj.config.Text_entfernt || "";
    if (/from Checkout/i.test(aobj.config.SQLselect))
        aobj.data.forEach(a => {cmap.set(String(a.idx),a)});
    else
        aobj.data.forEach(a => {amap.set(String(a.idx),a)});

    const defines={...format_defines,mkid:(idx)=>'id="'+adiv.id+'-'+idx+'"'};
    cmslib.mktpl(aobj.config.defines,defines);
    if (!aobj.config.preHTML && !aobj.config.postHTML && !aobj.config.ArtikelHTML)
    {
        aobj.config.ArtikelHTML=
`<div class="Artikel" data-idx="{idx}" {idx:mkid}>
{Foto:format_bild}
<p>{Bezeichnung}</p>
<p>{Kurztext}</p>
<p class="Preis">Preis: {_brutto:format_number} {1:format_eingabe}</p>
</div>`
    }
    cmslib.wrTableHTML(
        {anzart:aobj.data.length},
        aobj.data,
        defines,
        aobj.config.preHTML,
        aobj.config.ArtikelHTML,
        aobj.config.postHTML,
        '');
    adiv.delegate("click",function(ev)
    {
        var idx=this.dataset.idx;
        var data=amap.get(idx);
        var value=data.Typ=="Fixpreis" 
        ? 1 
        : (parseFloat(this.previousSibling.value)||0)+1;
        data._anzahl=value;
        data._menge_x_brutto=data._anzahl * data._brutto;
        data._menge_x_netto=data._anzahl * data._netto;
        data._menge_x_mwst=data._anzahl * data._mwst;
        if (!data._url)
            data._url=location.href;
        wk.set(idx,data);
        refreshwk();
        savewk(wk);
        if (aobj.config.SeiteWarenkorb)
            location.href=aobj.config.SeiteWarenkorb;
    },".wk-addbut")
}
Y.cms.shop.wrwarenkorb=function(wobj)
{
    var wdiv=document.currentScript.parentElement;
    wkdivs.set(wdiv,wobj)
    refreshwk([[wdiv,wobj]]);
    wdiv.closest("form")?.on("submit",Y.cms.shop.submitform=function(ev)
    {
        if (!Y.cms.shop?.onsubmit.call(this,ev))
        {
            ev?.preventDefault();
            return false;
        }
        const anz=[...wk.values()].reduce((a,v) => a+v._anzahl, 0);
        if (!anz && !wobj.leerbest)
        {
            if (wobj.WkLeerMsg)
                alert(wobj.WkLeerMsg);
            ev.preventDefault();
        }
        else
        {
            wk.forEach((data,idx) => {
                if (!data._anzahl)
                    wk.delete(idx);
            })
            refreshwk();
            this.querySelectorAll("[name='__anzahl']").forEach(i=>i.remove());
        }
        return cmslib.spamschutz(this);
    })
}
Y.cms.shop.putchkoutopt=function(idxtab,refresh)
{
    if (!(idxtab instanceof Array))
        idxtab=[idxtab];
    idxtab.forEach(idx => {
        const data=cmap.get(idx);
        chkoutopt.set(data.Kategorie,data);
    })
    if (refresh)
        refreshwk();
}
Y.cms.shop.putextra=function(extratab,refresh)
{
    // extratab: { Kategorie:String, Bezeichnung:String, Preis:Float, exklusiv:boolean, USt_Satz: String }
    if (!(extratab instanceof Array))
        extratab=[extratab];
    extratab.forEach(data => {
        if (data.Preis)
        {
            if (data.exklusiv)
            {
                data._brutto=data.Preis*(100+(data.USt_Satz||0))/100;
                data._netto=data.Preis;
                data._mwst=data._brutto - data._netto;
            }
            else
            {
                data._brutto=data.Preis;
                data._mwst=data.Preis * (data.USt_Satz||0) / 100;
                data._netto=data._brutto - data._mwst;
            }
            chkoutopt.set(data.Kategorie,data);
        }
        else
            chkoutopt.delete(data.Kategorie);
    })
    if (refresh)
        refreshwk();
}
var ts,oninput;
document.delegate("input",oninput=function(ev)
{
    var value=this.type == "checkbox" 
        ? this.checked 
            ? 1 
            : 0 
        : parseFloat(this.value)||0;
    var idx=this.dataset?.idx;
    if (!idx)
        return;
    for (n of (document.querySelectorAll(`[name='__anzahl'][data-idx='${idx}']`)||[]))
    {
        if (n.type == "checkbox")
            n.checked=value>0;
        else
            n.value=value;
    }
    var data=wk.get(idx) || amap.get(idx);
    this.nextSibling.innerHTML=value>data._anzahl ? (this.closest("[data-addmsg]")?.dataset.addmsg || 'zum Warenkorb hinzugefügt') : value<data._anzahl ? (this.closest("[data-delmsg]")?.dataset.delmsg || 'aus dem Warenkorb genommen') : '';
    ts=window.setTimeout(()=>{window.clearTimeout(ts); this.nextSibling.innerHTML='';},2000);
    data._anzahl=value;
    data._menge_x_brutto=data._anzahl * data._brutto;
    data._menge_x_netto=data._anzahl * data._netto;
    data._menge_x_mwst=data._anzahl * data._mwst;
    if (!data._url)
        data._url=location.pathname+location.search+'#'+this.closest("[id]:not(.jump-to-col)").id;
    wk.set(idx,data);
    refreshwk();
    savewk(wk);
},"[name='__anzahl']")

document.delegate("click",function(ev)
{
    var idx=this.dataset.idx;
    for (n of (document.querySelectorAll(`[name='__anzahl'][data-idx='${idx}']`)||[]))
    {
        if (n.type == "checkbox")
            n.checked=false;
        else
            n.value=0;
    }
    var data=wk.get(idx);
    var rec=this.parentElement.closest(`[data-idx='${idx}']`);
    
    data._anzahl=0;
    data._menge_x_brutto=0;
    data._menge_x_netto=0;
    data._menge_x_mwst=0;
    wk.delete(idx);
    refreshwk();
    savewk(wk);
},".entfernen[data-idx]")

document.delegate("click",Y.cms.shop.entleeren=function(ev)
{
    wk.forEach((data,idx) => {
        for (n of (document.querySelectorAll(`[name='__anzahl'][data-idx='${idx}']`)||[]))
        {
            if (n.type == "checkbox")
                n.checked=false;
            else
                n.value=0;
        }
        
        data._anzahl=0;
        data._menge_x_brutto=0;
        data._menge_x_netto=0;
        data._menge_x_mwst=0;
        wk.delete(idx);
    })
    refreshwk();
    savewk(wk);
},".entleeren")

document.delegate("click",function(ev)
{
    var inp=this.parentElement.querySelector("input");
    inp.value=Math.max(0,parseFloat(inp.value.replace(/,/,'.')||0)-1);
    oninput.call(inp,ev);
},".eingabe .minus");

document.delegate("click",function(ev)
{
    var inp=this.parentElement.querySelector("input");
    inp.value=parseFloat(inp.value.replace(/,/,'.')||0)+1;
    oninput.call(inp,ev);
},".eingabe .plus");

function refreshwk(wkd=wkdivs)
{
    const protab=[...wk.values()];
    for (const [w,data] of wkd)
    {
        let sumbrutto=0, sumnetto=0, summwst=0, sumanz=0;
        let znr=0;
        for (const d of protab)
        {
            d._trclass=`${znr===0?'first ':''}${znr===wk.size-1?'last ':''}${znr%2?'even':'odd'}`;
            ++znr
            
            d._menge_x_brutto=d._anzahl * d._brutto;
            d._menge_x_netto=d._anzahl * d._netto;
            d._menge_x_mwst=d._anzahl * d._mwst;

            sumbrutto+=d._menge_x_brutto;
            sumnetto+=d._menge_x_netto;
            summwst+=d._menge_x_mwst;
            sumanz+=d._anzahl;
        }
        let defs=data.defs;
        if (defs===undefined)
        {
            defs=data.defs={...format_defines};
            cmslib.mktpl(data.defines||'',defs);
        }
        const result=Y.cms.shop.wksum={
            _now:new Date(),
            _summe_brutto:sumbrutto,
            _summe_netto:sumnetto,
            _summe_mwst:summwst,
            _zwsumme_brutto:sumbrutto,
            _zwsumme_netto:sumnetto,
            _zwsumme_mwst:summwst,
            _summe_anzahl:sumanz,
            _positionen:znr
        }
        if (Y.cms.shop.onrefreshwk)
            Y.cms.shop.onrefreshwk(protab,chkoutopt,result);
        chkoutopt.forEach((v,k) => {
            result._summe_brutto+=v._brutto;
            result._summe_netto+=v._netto;
            result._summe_mwst+=v._mwst;
            result[k]={
                _brutto:v._brutto,
                _netto:v._netto,
                _mwst:v._mwst
            }
        })
        result._checkoutoptions=chkoutopt.size && defs.co_data
                ? cmslib.mkTableHTML(result,[...chkoutopt.values()],defs,defs.co_start||'',defs.co_data,defs.co_end||'','')
                : '';
        
        
        if (document.currentScript)
            cmslib.wrTableHTML(result,protab,defs,data.preHTML+(protab.length?'':data.leerHTML),data.ArtikelHTML,data.postHTML,'');
        else
            w.innerHTML=cmslib.mkTableHTML(result,protab,defs,data.preHTML+(protab.length?'':data.leerHTML),data.ArtikelHTML,data.postHTML,'');
        let form=w.closest("form");
        if (form)
        {
            if (!form._warenkorb)
                form.insertAdjacentHTML('afterbegin','<input name="_warenkorb" type="hidden">');
            form._warenkorb.value=JSON.stringify([...wk]);
            if (!form.wk)
                form.insertAdjacentHTML('afterbegin','<input name="wk" type="hidden">');
            form.wk.value=cmslib.mkTableHTML(result,protab,{...defs,Anzahl:n=>'{_}',Fixpreis:n=>'{_}',Menge:n=>'{_}',print_only:n=>n,entfernen:n=>'',entleeren:n=>''},data.preHTML,data.ArtikelHTML,data.postHTML,'');
        }
    }
}
Y.cms.shop.refresh=function()
{
    refreshwk();
    savewk(wk);
}
window.addEventListener("pageshow", () => {
  document.querySelectorAll("[name='__anzahl']").forEach(i=>i.value=wk.get(i.dataset.idx)?._anzahl||0);
});

 // }